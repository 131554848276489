import { extend } from 'vee-validate';

extend('pesel', {
  validate: (value) => {
    if (value.length !== 11) {
      return false;
    }
    // eslint-disable-next-line no-unused-vars
    let year = parseInt(value.substring(0, 2), 10);
    let month = parseInt(value.substring(2, 4), 10);
    // const day = parseInt(value.substring(4, 6), 10);

    if (month > 80) {
      year += 1800;
      month = month - 80;
    } else if (month >= 60) {
      year += 2200;
      month = month - 60;
    } else if (month >= 40) {
      year += 2100;
      month = month - 40;
    } else if (month >= 20) {
      year += 2000;
      month = month - 20;
    } else {
      year += 1900;
    }

    const weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
    let sum = 0;

    for (let i = 0; i < weights.length; i++) {
      sum += (parseInt(value.substring(i, i + 1), 10) * weights[i]);
    }

    sum = sum % 10;
    const controlNumber = parseInt(value.substring(10, 11), 10);
    const valid = (sum === controlNumber);
    return valid;
  }
});

extend("fieldConfirmation", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  }
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Powtórz hasło",
});

extend("passwordStrength", (value) => {
  var mediumRegex = new RegExp(
    "^(?=.*[A-Z])(?=.+$).{8,20}$"
  );
  if (mediumRegex.test(value)) {
    return true;
  }

  return "8 znaków";
});

extend("ibanValidator", (value) => {
  if (value.substring(0, 2).toLowerCase() !== 'pl') {
    value = 'PL' + value;
  }

  let valid = false;
  const codeLengths = {
    AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, CH: 21, CR: 21, CY: 28, CZ: 24,
    DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, LC: 30, FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28,
    HR: 21, HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21,
    MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
  };

  const iban = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  let digits;
  if (!code || iban.length !== codeLengths[code[1]]) {
    return valid ? null: false;
  }
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  const str = digits.toString();
  let checksum = str.slice(0, 2);
  let fragment = '';
  for (let offset = 2; offset < str.length; offset += 7) {
    fragment = checksum + str.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  if (checksum === 1) {
    valid = true;
  }

  return valid;
});

extend('noSpecialCharacters', {
  validate: (value) => {
      if (!value) {
          return false;
      }

      var letters = /[@~`!#$%^&*+=;_(){}|":<>\?]/g;
      if (!letters.test(value.trim())) {
          return true;
      }
      else {
          return false;
      }
  }
});

extend('withoutletter', {
  validate: (value) => {
      if (!value) {
          return false;
      }
      console.log(value.toLowerCase());
      var letters = /[oO]/gm;
      if (!letters.test(value.trim())) {
          return true;
      }
      else {
          return false;
      }
  }
});
